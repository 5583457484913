import React from 'react'

export default function about() {
    return (
        <React.Fragment>
            <h1>
                About
                <p>
                    This is an about page. 
                </p>
            </h1>
        </React.Fragment>
    )
}

//no need to export down here if you've already exported above.